import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import brand from "../../assets/dummy/brand";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import SidebarContent from "../Sidebar/SidebarContent";
import DropListMenu from "./DropListMenu";
import MegaMenu from "./MegaMenu";
import UserMenu from "./UserMenu";
import useStyles from "./header-jss";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LightModeIcon from "@mui/icons-material/LightMode";
import { connect } from "react-redux";
import { getUserPermissions } from "src/redux/actions/Operations";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HailIcon from "@mui/icons-material/Hail";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { POD } from "src/containers/pageListAsync";

const elem = document.documentElement;

function HeaderMenu(props) {
  const { classes, cx } = useStyles();
  const [fullScreen, setFullScreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openFullScreen = () => {
    setFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const UserType = localStorage.getItem("UserType");
  const UserEID = localStorage.getItem("EID");
  const closeFullScreen = () => {
    setFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "Escape") {
      closeFullScreen();
    }
  };

  // React.useEffect(() => {
  //   props.getUserPermissions();
  //   const roleData = JSON.stringify(
  //     props.userPermission && props.userPermission.data
  //   );
  //   localStorage.setItem("rolePermission", roleData);
  // }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  }, []);

  const turnMode = (mode) => {
    if (mode === "light") {
      props.changeMode("dark");
    } else {
      props.changeMode("light");
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    type,
    dataMenu,
    history,
    openGuide,
    mode,
    toggleDrawerOpen,
    openMobileNav,
    loadTransition,
    isLogin,
    logoLink,
    fixed,
  } = props;

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const dataMenuFiled = [
    {
      key: "seller",
      name: `${UserType == "seller" ? "My GSTIN" : "My Sellers"}`,
      icon: <SensorOccupiedIcon />,
      linkParent: `${
        UserType == "seller"
          ? `/app/my-sellers/seller?id=${UserEID}`
          : "/app/my-sellers"
      }`,
    },
    {
      key: "my-gstins",
      name: "My GSTIN",
      icon: <ReceiptLongIcon />,
      linkParent: `/app/my-sellers/seller?id=${UserEID}`,
    },
    {
      key: "buyer",
      name: "My Buyers",
      icon: <HailIcon />,
      linkParent: "/app/my-buyers",
    },
    {
      key: "invoices",
      name: "Invoices",
      icon: <ReceiptIcon />,
      linkParent: "/app/invoices",
    },
    {
      key: "invoice-verification",
      name: "Invoice Verification",
      icon: <ReceiptLongIcon />,
      linkParent: "/app/invoice-verification",
    },
    {
      key: "invoice-acceptance",
      name: "Invoice Acceptance",
      icon: <ReceiptLongIcon />,
      linkParent: "/app/invoice-acceptance",
    },
    {
      key: "pod",
      name: "Service POD",
      icon: <ReceiptLongIcon />,
      linkParent: "/app/service-pod",
    },
    {
      key: "mis",
      name: "MIS",
      icon: <ReceiptLongIcon />,
      linkParent: "/app/mis",
    },

    {
      key: "configuration",
      name: "Configurations",
      icon: <AdminPanelSettingsIcon />,
      child: [
        // {
        //   key: "profiles",
        //   name: "Profiles",
        //   link: "/app/configurations/profiles"
        // },
        {
          key: "users",
          name: "Users",
          link: "/app/configurations/users",
        },
        {
          key: "roles",
          name: "Roles",
          link: "/app/configurations/roles",
        },
        // {
        //   key: "financier",
        //   name: "Financier",
        //   link: "/app/configurations/financier"
        // }
      ],
    },
    // {
    //   key: "setting",
    //   name: "Settings",
    //   icon: <SettingsSuggestIcon />,
    //   child: [
    //     {
    //       key: "events",
    //       name: "Event logs",
    //       link: "/app/settings/event-logs"
    //     },
    //     {
    //       key: "notifications",
    //       name: "Notification Templates",
    //       link: "/app/settings/notification-templates"
    //     }
    //   ]
    // }
  ];
  // Create a mapping of Category_Name to Permission to show in the header menu or sidebar menu
  const permissionMap = {};
  props.userPermission.data &&
    props.userPermission.data?.forEach((item) => {
      // console.log("item.permissions",item)
      if (
        (item.Page_Name == "profiles" && item.Permission) ||
        (item.Page_Name == "users" && item.Permission) ||
        (item.Page_Name == "roles" && item.Permission) ||
        (item.Page_Name == "financier" && item.Permission)
      ) {
        // console.log("item.permissions",item.permissions)
        permissionMap["configuration"] = {
          child: [],
        };
        permissionMap.configuration.child.push(item);
      } else if (
        (item.Page_Name == "profiles" && !item.Permission) ||
        (item.Page_Name == "users" && !item.Permission) ||
        (item.Page_Name == "roles" && !item.Permission) ||
        (item.Page_Name == "financier" && !item.Permission)
      ) {
        permissionMap.configuration = false;
      } else if (
        (item.Page_Name == "events" && item.Permission) ||
        (item.Page_Name == "notifications" && item.Permission)
      ) {
        permissionMap["setting"] = {
          child: [],
        };
        permissionMap.setting.child.push(item);
        // console.log("item.permissions",item.permissions)
      } else if (
        (item.Page_Name == "events" && !item.Permission) ||
        (item.Page_Name == "notifications" && !item.Permission)
      ) {
        permissionMap.setting = false;
      } else {
        permissionMap[item.Page_Name] = item.Permission;
      }
    });
  if (props.userPermission && props.userPermission.data) {
    const roleData = JSON.stringify(
      props.userPermission && props.userPermission.data
    );
    localStorage.setItem("rolePermission", roleData);
  }
  // Update dataMenu with Permission values
  const updatedDataMenu = dataMenuFiled.map((item) => ({
    ...item,
    Permission: permissionMap[item.key] || false, // Default to false if no match found
  }));

  

  return (
    <AppBar className={cx(classes.appBar, classes.attachedbar, classes.fixed)}>
      {/* <div className={classes.appMenu}>
        {!lgUp && (
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={toggleDrawerOpen}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        {!mdDown && (
          <Fragment>
            <div className={classes.headerProperties}>
              <div className={cx(classes.headerAction, classes.invert)}>
                {fullScreen ? (
                  <Tooltip title="Exit Full Screen" placement="bottom">
                    <IconButton
                      className={classes.button}
                      onClick={closeFullScreen}
                      size="large"
                    >
                      <FullscreenExitIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Full Screen" placement="bottom">
                    <IconButton
                      className={classes.button}
                      onClick={openFullScreen}
                      size="large"
                    >
                      <FullscreenIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Turn Dark/Light" placement="bottom">
                  <IconButton
                    className={classes.button}
                    onClick={() => turnMode(mode)}
                    size="large"
                  >
                    <LightModeIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <NavLink to={logoLink} className={classes.brand}>
              <img src={"/images/logo.svg"} alt={brand.name} />
              {brand.name}
            </NavLink>
          </Fragment>
        )}
        <Toolbar>
          <UserMenu dark />
        </Toolbar>
      </div> */}
      {mdDown && (
        <div className={classes.appMenu}>
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={toggleDrawerOpen}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Toolbar>
            <UserMenu dark />
          </Toolbar>
        </div>
      )}

      {!lgDown && type === "mega-menu" ? (
        <MegaMenu dataMenu={dataMenuFiled} />
      ) : (
        <DropListMenu dataMenu={updatedDataMenu} />
      )}
      {!lgUp && (
        <SwipeableDrawer
          onClose={toggleDrawerOpen}
          onOpen={toggleDrawerOpen}
          open={!openMobileNav}
          anchor="left"
        >
          <div className={classes.swipeDrawerPaper}>
            <SidebarContent
              drawerPaper
              leftSidebar
              toggleDrawerOpen={toggleDrawerOpen}
              loadTransition={loadTransition}
              dataMenu={updatedDataMenu}
              anchorEl={anchorEl}
              openMenuStatus={handleOpen}
              closeMenuStatus={handleClose}
              isLogin={isLogin}
            />
          </div>
        </SwipeableDrawer>
      )}
    </AppBar>
  );
}

HeaderMenu.propTypes = {
  type: PropTypes.string,
  dataMenu: PropTypes.array,
  openMobileNav: PropTypes.bool,
  mode: PropTypes.string,
  changeMode: PropTypes.func,
  openGuide: PropTypes.func,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  fixed: PropTypes.any,
  logoLink: PropTypes.string,
  isLogin: PropTypes.bool,
  permissions: PropTypes.any,
  getUserPermissions: PropTypes.func,
};

HeaderMenu.defaultProps = {
  isLogin: true,
  logoLink: "/",
};
const mapStateToProps = (state) => ({
  userPermission: state.rootReducer.permissions.userPermissions,
});

export default connect(mapStateToProps, { getUserPermissions })(HeaderMenu);

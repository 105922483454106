const url = process.env.REACT_APP_API_BASE_PATH;

// auth routes
export const LOGIN = `${url}/user/login`;
export const SIGNUP = `${url}/user/signup`;

// roles routes
export const ROLES_LIST = `${url}/roles`;
export const ROLE_BY_ID = `${url}/roles/show`;
export const CREATE_ROLE = `${url}/roles/add`;
export const EDIT_ROLE = `${url}/roles/edit`;
export const CLONE_ROLE = `${url}/roles/clone`;
export const CHANGE_ROLE_STATUS = `${url}/roles/status`;
export const ROLE_ASSOCIATED_USERS = `${url}/roles/associated-users`;

// permission routes
// export const ALL_PERMISSIONS = `${url}/permissions`;
export const ALL_PERMISSIONS = `${url}/new/permissions`;
// export const USER_PERMISSIONS = `${url}/user/permission`;
export const USER_PERMISSIONS = `${url}/new/user/permission`;

// users routes
export const CREATE_USER = `${url}/user`;
export const USERS_LIST = `${url}/users`;
export const USER_BY_ID = `${url}/users/show`;
export const EDIT_USER = `${url}/users/edit`;
export const CHANGE_USER_STATUS = `${url}/users/status`;

// app routes
export const ALL_ENTITY_LIST = `${url}/entity-type`;
export const BUYER_SELLERS_LIST_INVOICE = `${url}/buyer-or-seller`;
export const BUYER_SELLERS_LIST = `${url}/entity/invoice/info`;
export const BUYER_SELLERS_LIST_BY_ID = `${url}/buyer-seller`;
export const ADD_BUYER_SELLER = `${url}/add/entities`;
export const EDIT_BUYER_SELLER = `${url}/buyer-seller/edit`;
export const UPLOAD_INVOICE = `${url}/invoice/add`;
export const INVOICE_LIST = `${url}/invoices`;
export const SEND_INVOICE = `${url}/invoices/send/acceptance`;
export const ACCEPT_REJECT_INVOICE = `${url}/invoices/sent/action`;
export const INVOICE_ACCEPTANCE_LIST = `${url}/invoices`;
export const SEND_RESET_PASSWORD_LINK = `${url}/user/forgot-password`;
export const TOKEN_VALIDATION_CALL = `${url}/user/check-email-token`;
export const RESET_PASSWORD_SUBMIT_CALL = `${url}/user/change-password`;
export const INVOICE_LIST_OCR = `${url}/invoices/ocr`;
export const INVOICE_LIST_LOGS = `${url}/invoices/logs`;
export const BUYER_SELLERS_LIST_AUTO_POPULATE = `${url}/entity/verification`;
export const INVOICE_SEND_VERIFICATION_REMINDER = `${url}/invoices/send/acceptance`;
export const INVOICE_SEND_FOR_VERIFICATION = `${url}/invoices/send/verifcation`;
export const INVOICE_VERIFICATION_SEND_LIST = `${url}/invoices`;
export const INVOICE_EWAY_BILL_LIST = `${url}/invoices/ewaybill`;
export const SEND_CONSENT_LINK = `${url}/invoices/ewaybill/user/add`;
export const ROLE_TYPE_LIST = `${url}/roles/type`;
export const ROLE_LIST_BY_ID = `${url}/role`;
export const ROLE_PAGES_LIST_BY_ID = `${url}/roles/show`;
export const DASHBOARD_DATA = `${url}/dashboard-data`;
export const ADD_ENTITIES_BY_ID = `${url}/add/entities`;
export const GET_BUYER_LIST = `${url}/buyer-list`;
export const GET_MIS_LIST = `${url}/seller/invoices/report`;
export const GET_POD_TABLE = `${url}/seller/invoices/report`;
export const ENTITY_LIST = `${url}/entities/anchor/list`;
export const ADD_ENTITY_POD_LIST = `${url}/add/entities/pod`;
export const UPLOAD_POD = `${url}/upload/entities/pod`;



export const POD_LIST = `${url}/entities/pod/list`;
export const POD_UPDATE= `${url}/update/entities/pod`;

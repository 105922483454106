import * as React from "react";
import Box from "@mui/material/Box";
import { Link, useLocation, useOutlet } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import useStyles from "./appStyles-jss";

export default function Configuration() {
  const outlet = useOutlet();
  const { classes, cx } = useStyles();
  const location = useLocation();

  // Function to determine if a Link is active
  const isLinkActive = (to) => {
    return location.pathname.includes(to);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        display="flex"
        justifyContent={"space-around"}
        sx={{ mt: "1em", mb: "1em" }}
      >
        {/* <Button
          component={Link}
          to="profiles"
          sx={{ width: "22%" }}
          className={cx(
            classes.configButtons,
            isLinkActive("profiles") && classes.activeLink
          )}
        >
          Profiles
        </Button> */}
        <Button
          component={Link}
          to="users"
          sx={{ width: "22%" }}
          className={cx(
            classes.configButtons,
            isLinkActive("users") && classes.activeLink
          )}
        >
          Users
        </Button>
        <Button
          component={Link}
          to="roles"
          sx={{ width: "22%" }}
          className={cx(
            classes.configButtons,
            isLinkActive("roles") && classes.activeLink
          )}
        >
          Roles
        </Button>
        {/* <Button
          component={Link}
          to="financier"
          sx={{ width: "22%" }}
          className={cx(
            classes.configButtons,
            isLinkActive("financier") && classes.activeLink
          )}
        >
          Financier
        </Button> */}
      </Grid>
      <div>{outlet}</div>
    </Box>
  );
}

export const initialState = {
  /* Settings for Themes and layout */
  theme: "blueCyanTheme",
  direction: "ltr",
  type: "light", // light or dark
  gradient: true, // true or false
  decoration: true, // true or false
  bgPosition: "half", // half, header, full
  layout: "top-navigation", // big-sidebar, left-sidebar, top-navigation, mega-menu
  /* End settings */
  palette: [
    { name: "Mint", value: "blueCyanTheme" },
    { name: "Monochrome", value: "greyTheme" }
  ],
  sidebarOpen: true,
  pageLoaded: true,
  loader: false,
  subMenuOpen: [],
  app: {
    users: false,
    userDataById: false,
    invoices: false,
    mySellers: false,
    sellerDataById: false,
    myBuyers: false,
    buyerDataById: false,
    profiles: false,
    financiers: false,
    rolesList: false,
    roleData: false,
    associateUsers: false,
    loading: false,
    error: false,
    invoiceListOcrData: false,
    invoiceListLogsData:false,
    buyerSellerAutoPopulateData:false
  },
  permissions: {
    allPermissions: false,
    userPermissions: false,
    profileData: false,
    entities: false,
    loading: false,
    error: false
  }
};

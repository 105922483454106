import * as Yup from "yup";

const gstInRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const panRegex = /^[A-Z]{3}[PCFTGHLABJ]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
const phoneNumberRegEx = /^[0-9]{10}$/;
const accountNumberRegex = /^[0-9]{1,}$/;
const ifscRegex = /^[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{1}$/;
function validateGSTIn(gstin) {
  return gstInRegex.test(gstin);
}
function validatePan(pan) {
  return panRegex.test(pan);
}

export default {
  LoginSchema: Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  }),
  ResetPasswordSchema: Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  }),
  RegisterSchema: Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().min(8).required("Password is required"),
  }),
  createEditRoleSchema: Yup.object().shape({
    Role_Name: Yup.string("Role name is required").required(
      "Role name is required"
    ),
    Description: Yup.string().required("Description is required"),
    RoleType: Yup.string().required("Please select role type"),
    Permission: Yup.mixed().nullable(),
    // .when("EDIT_FLAG", {
    //   is: false,
    //   then: () =>
    //     Yup.mixed("Please select permission for creating a role").required(
    //       "Role permissions are required"
    //     ),
    //   otherwise: () =>
    //     Yup.string("Please select permission for creating a role").nullable()
    // })
  }),
  invoiceDisputeSchema: Yup.object().shape({
    Module: Yup.string("Module is required").required("Module is required"),
    Description: Yup.string().required("Description is required"),
  }),
  cloneRoleSchema: Yup.object().shape({
    Role_Name: Yup.string("Role name is required").required(
      "Role name is required"
    ),
    Description: Yup.string().required("Description is required"),
    RoleType: Yup.string().required("Please select role type"),
  }),
  createEditUserSchema: Yup.object().shape({
    FirstName: Yup.string("First name is required").required(
      "First name is required"
    ),
    LastName: Yup.string("Last name is required").required(
      "Last name is required"
    ),
    Email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    ProfilePic: Yup.string("Email is required").nullable(),
    Address: Yup.string("Address is required").required("Address is required"),
    Country: Yup.string("Country is required")
      // .nullable()
      .required("Country is required"),
    State: Yup.string("State is required").required("State is required"),
    City: Yup.string("City is required").required("City is required"),
    Mobile: Yup.string("Mobile is required")
        .matches(/^[0-9]{10}$/, 'Mobile must be 10 digits')
        .required('Mobile is required'),
    Phone: Yup.string().nullable().matches(/^[0-9]{10}$/, 'Phone must be 10 digits'),
    Zip: Yup.string('Zip is required').max(6, 'Zip code cannot be longer than 6 characters').required('Zip is required'),
    Remarks: Yup.string().nullable().max(200, 'Remarks cannot be longer than 200 characters'),
    RoleId: Yup.string("Role is required").required("Role is required"),
    UserType: Yup.string("User Type is required").required(
      "User Type is required"
    ),
    ClientType: Yup.string("Client Type is required").nullable(),
  }),
  createSellerBuyerSchema: Yup.object({
    AccountName: Yup.string("Account Name is required").required(
      "Account Name is required"
    ),
    PAN: Yup.string("PAN is required")
      .matches(panRegex, "Invalid Pan number")
      .min(10)
      .max(10)
      .required("PAN is required"),
    user: Yup.array().of(
      Yup.object().shape({
        FirstName: Yup.string("First name is required").required(
          "First name is required"
        ),
        LastName: Yup.string("Last name is required").required(
          "Last name is required"
        ),
        Email: Yup.string()
          .email("Email must be a valid email address")
          .required("Email is required"),

        Address1: Yup.string("Address is required").required(
          "Address is required"
        ),
        Country: Yup.string("Country is required").required(
          "Country is required"
        ),
        State: Yup.string("State is required").required("State is required"),
        City: Yup.string("City is required").required("City is required"),
        Mobile: Yup.string("Mobile is required").required("Mobile is required"),
        Zip: Yup.string("Zip is required").required("Zip is required"),
        GSTIN_No: Yup.string("GSTIN No is required")
          .matches(gstInRegex, "Invalid GSTIN number")
          .min(15)
          .max(15)
          .required("GSTIN No is required"),
        Trade_Name: Yup.string("Trade Name is required").required("Trade Name is required"),
        Account_Number: Yup.string("Account Number is required")
          .matches(accountNumberRegex, "invalid account number")
          .required("Account Number is required"),
        IFSC: Yup.string("IFSC is required")
          .matches(ifscRegex, "invalid IFSC")
          .required("IFSC is required"),
        Beneficiary_Name: Yup.string("Beneficiary Name is required").required(
          "Beneficiary Name is required"
        ),
        Phone_No: Yup.string("Phone Number is required")
          .matches(phoneNumberRegEx, "invalid Phone Number")
          .required("Phone Number is required"),
        Operate_As: Yup.string("Operate As is required").required(
          "Operate As is required"
        ),
        Business_Type: Yup.string().required("Business Type is required"),
      })
    ),
  }),
  createSellerBuyerSchemaBuyerForm: Yup.object({
    AccountName: Yup.string("Account Name is required").required(
      "Account Name is required"
    ),
    PAN: Yup.string("PAN is required")
      .matches(panRegex, "Invalid Pan number")
      .min(10)
      .max(10)
      .required("PAN is required"),
    user: Yup.array().of(
      Yup.object().shape({
        FirstName: Yup.string("First name is required").required(
          "First name is required"
        ),
        LastName: Yup.string("Last name is required").required(
          "Last name is required"
        ),
        Email: Yup.string()
          .email("Email must be a valid email address")
          .required("Email is required"),

        Address1: Yup.string("Address is required").required(
          "Address is required"
        ),
        Country: Yup.string("Country is required").required(
          "Country is required"
        ),
        State: Yup.string("State is required").required("State is required"),
        City: Yup.string("City is required").required("City is required"),
        Mobile: Yup.string("Mobile is required").required("Mobile is required"),
        Zip: Yup.string("Zip is required").required("Zip is required"),
        GSTIN_No: Yup.string("GSTIN No is required")
          .matches(gstInRegex, "Invalid GSTIN number")
          .min(15)
          .max(15)
          .required("GSTIN No is required"),
        Trade_Name: Yup.string("Trade Name is required").required("Trade Name is required"),
        Operate_As: Yup.string("Operate As is required").required(
          "Operate As is required"
        ),
      })
    ),
  }),
  createSellerBuyerSchemaGST: Yup.object({
    AccountName: Yup.string("Account Name is required").required(
      "Account Name is required"
    ),
    PAN: Yup.string("PAN is required")
      .matches(panRegex, "Invalid Pan number")
      .min(10)
      .max(10)
      .required("PAN is required"),
    user: Yup.array().of(
      Yup.object().shape({
        FirstName: Yup.string("First name is required").required(
          "First name is required"
        ),
        LastName: Yup.string("Last name is required").required(
          "Last name is required"
        ),
        Email: Yup.string()
          .email("Email must be a valid email address")
          .required("Email is required"),

        Address1: Yup.string("Address is required").required(
          "Address is required"
        ),
        Country: Yup.string("Country is required").required(
          "Country is required"
        ),
        State: Yup.string("State is required").required("State is required"),
        City: Yup.string("City is required").required("City is required"),
        Mobile: Yup.string("Mobile is required").required("Mobile is required"),
        Zip: Yup.string("Zip is required").required("Zip is required"),
        GSTIN_No: Yup.string("GSTIN No is required")
          .matches(gstInRegex, "Invalid GSTIN number")
          .min(15)
          .max(15)
          .required("GSTIN No is required"),
        Trade_Name: Yup.string("Trade Name is required").required("Trade Name is required"),
        Account_Number: Yup.string("Account Number is required")
          .matches(accountNumberRegex, "invalid account number")
          .required("Account Number is required"),
        IFSC: Yup.string("IFSC is required")
          .matches(ifscRegex, "invalid IFSC")
          .required("IFSC is required"),
        Beneficiary_Name: Yup.string("Beneficiary Name is required").required(
          "Beneficiary Name is required"
        ),
        Phone_No: Yup.string("Phone Number is required")
          .matches(phoneNumberRegEx, "invalid Phone Number")
          .required("Phone Number is required"),
        Operate_As: Yup.string("Operate As is required").required(
          "Operate As is required"
        ),
      })
    ),
  }),
  createSellerBuyerSchemaBuyerFormGST: Yup.object({
    AccountName: Yup.string("Account Name is required").required(
      "Account Name is required"
    ),
    PAN: Yup.string("PAN is required")
      .matches(panRegex, "Invalid Pan number")
      .min(10)
      .max(10)
      .required("PAN is required"),
    user: Yup.array().of(
      Yup.object().shape({
        FirstName: Yup.string("First name is required").required(
          "First name is required"
        ),
        LastName: Yup.string("Last name is required").required(
          "Last name is required"
        ),
        Email: Yup.string()
          .email("Email must be a valid email address")
          .required("Email is required"),

        Address1: Yup.string("Address is required").required(
          "Address is required"
        ),
        Country: Yup.string("Country is required").required(
          "Country is required"
        ),
        State: Yup.string("State is required").required("State is required"),
        City: Yup.string("City is required").required("City is required"),
        Mobile: Yup.string("Mobile is required").required("Mobile is required"),
        Zip: Yup.string("Zip is required").required("Zip is required"),
        GSTIN_No: Yup.string("GSTIN No is required")
          .matches(gstInRegex, "Invalid GSTIN number")
          .min(15)
          .max(15)
          .required("GSTIN No is required"),
        Trade_Name: Yup.string("Trade Name is required").required("Trade Name is required"),
        Operate_As: Yup.string("Operate As is required").required(
          "Operate As is required"
        ),
      })
    ),
  }),
  ocrSchema: Yup.object({
    Invoice_Date: Yup.string()
      .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (yyyy-mm-dd)")
      .required("Invoice Date is required"),
    Invoice_No: Yup.string().required("Invoice No is required"),
    // PO_No: Yup.string().required("PO No is required"),
    Supplier_Name: Yup.string().required("Vendor Name is required"),
    Supplier_Gstin: Yup.string().required("Vendor GSTIN is required"),
    Supplier_Address1: Yup.string().required("Vendor Address is required"),
    Buyer_Name: Yup.string().required("Buyer Name is required"),
    Billing_Gstin: Yup.string().required("Billing GSTIN is required"),
    Billing_Address1: Yup.string().required("Billing Address is required"),
    Total_Inv_Value: Yup.string().required("Total Inv Value is required"),
    // Shipping_Gstin: Yup.string().required("Ship To GSTIN is required"),
    // Shipping_Address1: Yup.string().required("Ship To Address is required"),
    // IRN: Yup.string().required("IRN is required"),
  }),
};

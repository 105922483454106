import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Items } from "./items";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { useStyles } from "src/hooks/useStyles";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { classes } = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const changeRoutes = (page) => {
    handleCloseUserMenu();
    handleCloseNavMenu();
    navigate(page);
  };

  return (
    <AppBar position="static" className={classes.header}>
      <Container maxWidth={false} disableGutters style={{ height: "100%" }}>
        <Grid container>
          <Grid item xs={4} className={classes.headergrid}>
            <Typography
              variant="h6"
              noWrap
              onClick={() => navigate("/")}
              sx={{
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".3rem",
                cursor: "pointer"
              }}
            >
              Accept My Invoice
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" }
                }}
              >
                {Items.menu.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => changeRoutes(page.to)}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Grid>
          <Grid
            item
            xs={7}
            sm={8}
            sx={{
              alignItems: "center",
              display: "flex"
            }}
          >
            <Typography
              variant="h5"
              noWrap
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: { xs: "center" },
                width: { xs: "100%" },
                cursor: "pointer"
              }}
              onClick={() => navigate("/")}
            >
              LOGO
            </Typography>
            {/* <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: { md: "end" }
              }}
            >
              {Items.menu.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => changeRoutes(page.to)}
                  className={classes.headerMenus}
                >
                  {page.name}
                </Button>
              ))}
            </Box> */}
          </Grid>
          {/* <Grid item xs={3} sm={2} className={classes.headergrid}>
            {!user && (
              <Button
                variant="contained"
                onClick={() => navigate("/register")}
                className={classes.headerAuth}
              >
                Sign up
              </Button>
            )}
          </Grid> */}
        </Grid>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

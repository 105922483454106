import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material";
export const useStyles = makeStyles()((theme) => ({
  // header
  header: {
    height: "100px",
    background: alpha(theme.palette.primary.main, 0.9),
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "75px"
    }
  },
  headergrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  headerMenus: {
    margin: "2em",
    color: "white",
    display: "block"
  },
  headerAuth: {
    background: "white",
    color: theme.palette.primary.main,
    transition: "all 0.1s ease-in-out",
    width: "100px",
    borderRadius: "12px",
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
      transform: `scale(1.1)`
    }
  }
}));

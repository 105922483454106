// /* eslint-disable */

import React from "react";
import Loading from "../components/Loading";
import loadable from "../utils/loadable";

export const DashboardPage = loadable(() => import("./Pages/Dashboard"), {
  fallback: <Loading />
});
export const Table = loadable(() => import("./Pages/Table/BasicTable"), {
  fallback: <Loading />
});
export const Login = loadable(() => import("./Pages/Auth/Login"), {
  fallback: <Loading />
});

export const Register = loadable(() => import("./Pages/Auth/Register"), {
  fallback: <Loading />
});
export const ResetPassword = loadable(
  () => import("./Pages/Auth/ResetPassword"),
  {
    fallback: <Loading />
  }
);
export const NotFound = loadable(() => import("./NotFound/NotFound"), {
  fallback: <Loading />
});
export const SessionOut = loadable(() => import("./Pages/Auth/SessionOut"), {
  fallback: <Loading />
});
export const SendInvoice = loadable(() => import("./Pages/Invoice/SendInvoice"), {
  fallback: <Loading />
});

export const Error = loadable(() => import("./Pages/Error"), {
  fallback: <Loading />
});

export const Users = loadable(() => import("./Pages/Users"), {
  fallback: <Loading />
});

export const Clients = loadable(() => import("./Pages/Clients"), {
  fallback: <Loading />
});

export const Financier = loadable(() => import("./Pages/Financier"), {
  fallback: <Loading />
});

export const RoleForm = loadable(() => import("./Forms/RoleForm"), {
  fallback: <Loading />
});

export const SellerBuyerForm = loadable(
  () => import("./Forms/SellerBuyerForm"),
  {
    fallback: <Loading />
  }
);

export const AddGSTINForm = loadable(
  () => import("./Forms/AddGSTIN"),
  {
    fallback: <Loading />
  }
);

export const UserForm = loadable(() => import("./Forms/UserForm"), {
  fallback: <Loading />
});

export const RolesTable = loadable(() => import("./Pages/Roles"), {
  fallback: <Loading />
});

export const SellerTable = loadable(() => import("./Pages/Seller"), {
  fallback: <Loading />
});

export const SellerPage = loadable(() => import("./Pages/Seller/seller"), {
  fallback: <Loading />
});

export const SellerInvoice = loadable(() => import("./Pages/Seller/SellerInvoice"), {
  fallback: <Loading />
});

export const InvoicePage = loadable(() => import("./Pages/Invoice/invoice"), {
  fallback: <Loading />
});


  
export const BuyerTable = loadable(() => import("./Pages/Buyer"), {
  fallback: <Loading />
});

export const BuyerPage = loadable(() => import("./Pages/Buyer/buyer"), {
  fallback: <Loading />
});

export const InvoiceAcceptancePage = loadable(() => import("./Pages/Invoice/InvoiceAcceptance"), {
  fallback: <Loading />
});

export const MisReport = loadable(() => import("./Pages/Reports/MisReport"), {
  fallback: <Loading />
});

export const InvoiceVerificationPage = loadable(() => import("./Pages/Invoice/InvoiceVerificationPage"), {
  fallback: <Loading />
});

export const BuyerInvoice = loadable(() => import("./Pages/Buyer/BuyerInvoice"), {
  fallback: <Loading />
});

export const ResetPasswordSend = loadable(() => import("./Pages/Users/ResetPasswordSend"), {
  fallback: <Loading />
});

export const InvoiceDetails = loadable(() => import("./Pages/Invoice/InvoiceDetails"),
  {fallback: <Loading />}
);
export const LogsDetails = loadable(() => import("./Pages/Invoice/LogsDetails"),
  {fallback: <Loading />}
);

export const InvoiceLogs = loadable(() => import("./Pages/Invoice/InvoiceLogs"),
  {fallback: <Loading />}
);

export const EWayDetails = loadable(() => import("./Pages/EWay/EWayDetails"),
  {fallback: <Loading />}
);

export const VerifyInvoice = loadable(() => import("./Pages/Invoice/invoiceVerify"),
  {fallback: <Loading />}
);

export const CapturePhoto = loadable(() => import("./Pages/Invoice/CapturePhoto"),
  {fallback: <Loading />}
);

export const POD = loadable(() => import("./Pages/POD/pod"),
  {fallback: <Loading />}
);


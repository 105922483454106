import React from "react";
import PropTypes from "prop-types";
import { NavLink, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import brand from "../../assets/dummy/brand";
import { TextFieldRedux } from "./ReduxFormMUI";
import useStyles from "./user-jss";
import { Box, FormLabel, TextField } from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { Form, FormikProvider, useFormik } from "formik";
import Validations from "src/utils/formValidation";
import { resetPasswordSendCall } from "src/api_operations/actions";
import { Loading } from "src/redux/actions/uiActions";
import { useSnackbar } from "notistack";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

function ResetForm(props) {
  const { classes, cx } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { callResetPassword, data, close, deco } = props;
  console.log("props", props);

  const onSubmit = () => {
    const resetPasswordSend = {
      email: formik.values.email,
    };
    Loading(true);
    props.resetPasswordSendCall(
      resetPasswordSend,
      (res) => {
        Loading(false);
        if (res.status == true) {
          enqueueSnackbar(`${res.message}`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${res.message}`, {
            variant: "success",
          });
        }
      },
      (err) => {
        Loading(false);
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      }
    );

  };

  const formik = useFormik({
    initialValues: {
      email: data?.Email || "",
    },
    validationSchema: Validations.ResetPasswordSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  // console.log("ResetForm", data);
  // console.log("formik", formik);
  return (
    <Paper className={cx(classes.paperWrap, deco && classes.petal)}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <NavLink to="/" className={cx(classes.brand, classes.outer)}>
          <img src={"/images/logo.svg"} alt={brand.name} />
          {brand.name}
        </NavLink>
        {user ? (
          <Button size="small" className={classes.buttonLink} onClick={close}>
            Close
          </Button>
        ) : (
          <Button
            size="small"
            className={classes.buttonLink}
            component={LinkBtn}
            to="/login"
          >
            Login
          </Button>
        )}
      </Box>
      <Typography variant="h5" className={classes.title} gutterBottom>
        Reset Password
      </Typography>
      <Typography
        variant="caption"
        className={classes.subtitle}
        gutterBottom
        align="center"
      >
        Send reset password link to Your email
      </Typography>
      <section className={classes.formWrap} style={{ marginTop: "1em" }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <FormControl variant="standard" className={classes.formControl}>
                <FormLabel>
                  Email<span>*</span>
                </FormLabel>
                <TextField
                  name="email"
                  variant="standard"
                  {...getFieldProps("email")}
                  required
                  className={classes.field}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
            </div>
            <div className={classes.btnArea}>
              <Button
                variant="contained"
                disabled={isSubmitting}
                color="primary"
                type="submit"
              >
                Send Reset Link
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </section>
    </Paper>
  );
}

ResetForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  deco: PropTypes.bool,
  data: PropTypes.object,
  close: PropTypes.func,
  resetPasswordSendCall: PropTypes.func,
};

const ResetFormReduxed = reduxForm({
  form: "resetFrm",
  enableReinitialize: true,
})(ResetForm);

const mapStateToProps = (app) => ({
  data: app.rootReducer,
});

const RegisterFormMapped = connect((state) => ({
  deco: state.rootReducer.ui.decoration,
}))(ResetFormReduxed);

export default connect(mapStateToProps, {
  resetPasswordSendCall,
  Loading,
})(ResetForm);

import * as urls from "../utils/urls";
import { post, put, get } from "./httpMethods";

// GLOBAL CALLS

const getLocationFromPinUrl = process.env.REACT_APP_PINCODE_API_PATH;

export const getLocationFromPinCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(`${getLocationFromPinUrl}/${payload}`);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const loginCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.LOGIN, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const signUpCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.SIGNUP, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

// roles related calls
export const createRoleCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.CREATE_ROLE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const editRoleCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.EDIT_ROLE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const changeRoleStatus =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.CHANGE_ROLE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const cloneRoleCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.CLONE_ROLE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

// USERS RELATED CALLS
export const createUserCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.CREATE_USER, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

// USERS RELATED CALLS
export const editUserCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.EDIT_USER, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

  export const updatePOD =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.POD_UPDATE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const changeUserStatus =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.CHANGE_USER_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

// USERS Entity RELATED CALLS
export const createBuyerSellerCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.ADD_BUYER_SELLER, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const editBuyerSellerCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.EDIT_BUYER_SELLER, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const invoiceUploadCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.UPLOAD_INVOICE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
  export const addPODCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.ADD_ENTITY_POD_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  }

  export const uploadPODCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.UPLOAD_POD, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  }

  export const editEWayBillDetails=
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.INVOICE_EWAY_BILL_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const invoiceSendCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.SEND_INVOICE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const acceptRejectInvoiceCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.ACCEPT_REJECT_INVOICE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const resetPasswordSendCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.SEND_RESET_PASSWORD_LINK, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const tokenValidationCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.TOKEN_VALIDATION_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const resetPasswordSubmitCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.RESET_PASSWORD_SUBMIT_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const editInvoiceCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(urls.INVOICE_LIST_OCR, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

  export const invoiceSendReminderCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.INVOICE_SEND_VERIFICATION_REMINDER, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

  export const invoiceSendForVerificationCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.INVOICE_SEND_FOR_VERIFICATION, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };